import { registration } from './registration'
import { auth } from './auth'
import { AddLabel } from './AddLabel'
import { AddProjectName } from './AddProjectName'
import { ChangePassApi } from './ChangePassApi'
const ApiCalls={
    registration,
    auth,
    ChangePassApi,
    AddProjectName,
    AddLabel,
}
export {ApiCalls}