import React from 'react'
import { FaqsComponent } from '../../components/FaqsComponent'

const Faqs = () => {
  return (
    <div>
        <FaqsComponent />
    </div>
  )
}

export {Faqs}