import React from 'react'
import { Col, Flex, Image, Row, Typography } from 'antd'

const AboutComponent = () => {

  return (
    <Flex justify='center' className='secondary-bg'>
      <div className='width-93 my-5'>
        <Row gutter={[32, 32]} align='middle' className='m-0'>
            <Col xs={24} sm={24} md={12}>
              <Typography.Title className='primary-heading m-0 mb-3' level={1}>
                About MLScholar
              </Typography.Title>
              <Typography.Text className='body-text text-justify'>
                MLScholar makes machine learning accessible and engaging. Whether you're tackling a marketing class project or exploring data science, our platform allows you to interactively train and test supervised machine learning models with real-world applications. 
                MLScholar equips you with the tools to apply theoretical knowledge in practical scenarios, enhancing your learning and your projects
              </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Flex justify='center'>
                <Image src='/assets/ab-1.png' preview={false} />
              </Flex>
            </Col>
        </Row>
      </div>
    </Flex>
  )
}

export {AboutComponent}
