import { useState } from 'react'
import { Button, Modal, Flex, Typography} from 'antd'
import { useNavigate } from 'react-router-dom'
import { RegistrationModal } from '../RegistrationModal'
import { LoginModal } from '../LoginModal'

const {Text}= Typography

const LoginRegisterIntroModal = ({open, onClose}) => {
  
    const navigate = useNavigate()
    const [openModal, setOpenModal]= useState(false)
    const [openLoginModal, setOpenLoginModal]= useState(false)
    const data = [
      {
          title:"First Visit?",
          btntext:"Register",
          open: ()=> setOpenModal(true),
      },
      {
          title:"Have an account?",
          btntext:"Login",
          open: ()=> {onClose();setOpenLoginModal(true)},
      },
      {
          title:"Explore as a guest",
          btntext:"Start Now",
          path:"/",
      }
  ]

  return (
    <>
        <Modal
            open={open}
            title="Launch into Machine Learning"
            onCancel={onClose}
            centered
            footer={null}
            width={'600px'}
        >
        <Flex vertical gap={20} className='my-5'>
            {
            data?.map((data, index)=>
                <Flex wrap gap="small" justify='space-between' align='center' key={index}>
                    <Text className='body-text'>
                        {
                            data?.title
                        }
                    </Text >
                    <Button 
                        onClick={()=>{data?.open ? data?.open() : navigate(data?.path)}} 
                        className='sm-btn brand-bg text-white'
                    >
                        {
                            data?.btntext
                        }
                    </Button>
                </Flex>
            )
            }
        </Flex>
        </Modal>
        <RegistrationModal open={openModal} onClose={() => setOpenModal(false)}/>
        <LoginModal open={openLoginModal} onClose={() => setOpenLoginModal(false)}/>
    </>
  )
};
export {LoginRegisterIntroModal}