import React from 'react'
import { AboutComponent, WelcomeSection, WhyChoose } from '../../components'

const HomePage = () => {


  return (
    <div>
      <WelcomeSection />
      <AboutComponent />
      <WhyChoose />
    </div>
  )
}

export {HomePage}