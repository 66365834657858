import React, { useState } from 'react'
import { Button, Card, Col, Flex, Form, Input, Row, Typography } from 'antd'
import './index.css'
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"
import { MyInput } from '../../components'

const Login = () => {

    const [form] = Form.useForm()
    const appDispatcher = useDispatch()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)

    
    
    const login = () => {
        if(forgotPassword)
            appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        else
            appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    }

  return (
    <Flex className='h-100vh' justify='center' align='center'>
        <div className='Form'>
            <Card className='cs-login'>
                <Typography.Title level={1} className='primary-heading text-center mb-3'>
                    Login
                </Typography.Title>
                <Form
                        form={form}
                        layout='vertical'
                        onFinish={login}
                        requiredMark={false}
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            <MyInput
                                autoFocus
                                name="email"
                                label="Email"
                                required
                                message='please enter email'
                                value={form.getFieldValue("email") || ''}
                            />
                        </Col>
                        <Col span={24}>
                            <MyInput
                                name="password"
                                type='password'
                                label="Password"
                                required
                                message='please enter password'
                                value={form.getFieldValue("password") || ''}
                            />
                        </Col>
                        <Col span={24}>
                            <Button 
                                loading={loading} 
                                htmlType="submit" 
                                className='sm-btn brand-bg text-white'
                            >
                                Log In
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    </Flex>
  )
}

export {Login}