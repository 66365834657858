import React from 'react'
import { AboutComponent } from '../../components/AboutComponent'

const AboutUs = () => {


  return (
    <div>
      <AboutComponent />
    </div>
  )
}

export {AboutUs}