import { Button, Card, Flex, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.css'
const {Text, Title}= Typography
const LoginIntro = () => {

    const navigate = useNavigate()
    const data = [
        {
            title:"First Visit?",
            btntext:"Register",
            path:"/register",
        },
        {
            title:"Have an account?",
            btntext:"Login",
            path:"/login",
        },
        {
            title:"Explore as a guest",
            btntext:"Start Now",
            path:"/",
        },
    ]

  return (
    <Flex className='my-5' justify='center'>
        <div className='login-Form'>
            <Title level={1} className='primary-heading text-center mb-3'>
                Launch into Machine Learning
            </Title>
            <Card className='card-login'>
                {
                    data?.map((data,d)=>
                        <Flex className='mb-3' wrap gap="small" justify='space-between' align='center' key={d}>
                            <Text className='body-text'>
                                {
                                    data?.title
                                }
                            </Text >
                            <Button onClick={()=>navigate(data?.path)} className='sm-btn brand-bg text-white'>
                                {
                                    data?.btntext
                                }
                            </Button>
                        </Flex>
                    )
                }
            </Card>
        </div>
    </Flex>
  )
}

export {LoginIntro}