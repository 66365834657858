import { Typography, Row, Col, Space} from "antd"
import { MyInput } from "../../../Forms"

const {Text, Title}= Typography

const Instructor= ()=>{

    return (
        <Row gutter={[32,0]}>
            <Col span={12}>
                <MyInput
                    name="firstName"
                    label="First name"
                    placeholder= 'John'
                    required
                    message='please enter first name'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="lastName"
                    label="Last name"
                    placeholder= 'Doe'
                    required
                    message='please enter last name'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="email"
                    label="Email"
                    placeholder= 'instructor@gmail.com'
                    required
                    message='please enter email'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="universityProfileWebsite"
                    label="University Profile Website"
                    placeholder= 'cityuniversity.com/instructor/johndoe'
                    required
                    message='please enter university profile website'
                    size='large'
                />
            </Col>
            <Col span={24}>
                <Space direction="vertical" className='mb-3'>
                    <Title className='brand-color m-0' level={4}>
                        To create a class account.
                    </Title>
                    <Text strong>
                        If you are setting up an account for your class, please provide the following:
                    </Text>
                </Space>
            </Col>
            <Col span={12}>
                <MyInput
                    type='number'
                    name="numberOfStudent"
                    label="Number of students in your class"
                    placeholder={50}
                    required
                    message='please enter no of student'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="className"
                    label="Class name"
                    placeholder= 'BS-IT'
                    required
                    message='please enter class name'
                    size='large'
                />
            </Col>
            <Col span={24}>
                <MyInput
                    name="password"
                    type='password'
                    label="Password"
                    required
                    message='please enter password'
                    placeholder='e.g A@123456'
                    size='large'
                />
            </Col>
        </Row>
    )
}
export {Instructor}