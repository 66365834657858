import React from 'react'
import { Card, Col, Flex, Image, Row, Typography } from 'antd'

const CourseworkCard = () => {

    const data = [
        {
            img:"client.png",
            title:"Brand Logo Recognition Using ML"
        },
        {
            img:"local.png",
            title:"Assignment 1."
        },
    ]

  return (
    <Flex justify='center'  className='mar-10 pb-3'>
        <div className='width-93'>
            <Row gutter={[16,16]} justify='center'>
                {
                    data?.map((data,d)=>
                        <Col xs={24} sm={24} md={12} lg={6} key={d}> 
                            <Card className='card-login h-100'>
                                <Flex justify='center'>
                                    <Image src={'/assets/'+data?.img} width={'70%'} height={200} preview={false} />
                                </Flex>
                                <Typography.Title level={4} className='secondary-heading text-center m-0 mb-3'>
                                    {
                                        data?.title
                                    }
                                </Typography.Title>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </div>
    </Flex>
  )
}

export {CourseworkCard}