import React from 'react'
import { ConfigProvider } from 'antd';
import MLRouter from './MLRouter';
import { Provider } from 'react-redux'
import { store } from './shared'
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#063e33',
        },
      }}
    >
      <Provider store={store}>  
        <MLRouter />
      </Provider>
    </ConfigProvider>
  )
}

export default App;
