import React from 'react'
import { Button, Flex, Image, Space, Typography } from 'antd'

const StartTraining = () => {


    const data = {
        primarytext:"Progress Update.",
        desc: "You've successfully gathered image samples for the computer to recognize different labels.",
        list:[
            {
                listtext:"-Label 1: # examples collected"
            },
            {
                listtext:"-Label 2: # examples collected"
            },
        ],
        secondarytext:"Tesst your model",
        descsecondary:"Upload an image or enter its URL to see how well your trained model recognizes it",
        img:"client.png",
        question:"Ready to enhance your capabilities?",
        thirdtext:"Train a new machine learning model now:",
        lasttext:"Recognized as [label#] with 99% of confidence."
    }

  return (
    <Flex className='mar-10 pt-2' justify='center'>
        <div className='width-93'>
            <Space direction='vertical' size={20}>
                <Typography.Title level={2} className='m-0 secondary-heading '>
                    {
                        data?.primarytext
                    }
                </Typography.Title>
                <Typography.Text className='body-text '>
                    {
                        data?.desc
                    }
                </Typography.Text>
                
                <ul>
                    {
                        data?.list?.map((dat,d)=>
                            <li key={d}>
                                <Typography.Text className='body-text'>
                                    {
                                        dat?.listtext
                                    }
                                </Typography.Text>
                            </li>
                        )
                    }
                </ul>
                <Typography.Title level={2} className='secondary-heading
                 m-0'>
                    {
                        data?.secondarytext
                    }
                </Typography.Title>
                <Typography.Text className='body-text'>
                    {
                        data?.descsecondary
                    }
                </Typography.Text>
                <Image src={'/assets/'+data?.img} width={120} />
                <Typography.Text className='body-text'>
                    {
                        data?.question
                    }
                </Typography.Text>
                <Typography.Title level={4} className='secondary-heading
                 m-0'>
                    {
                        data?.thirdtext
                    }
                </Typography.Title>
                <Button className='med-btn brand-bg text-white'>
                    Begin training
                </Button>
                <Typography.Text className='body-text'>
                    {
                        data?.lasttext
                    }
                </Typography.Text>
            </Space>
        </div>
    </Flex>
  )
}

export {StartTraining}