import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	addsample,
	projects
} from "./action"
const store = configureStore({
	reducer: {
		login,
		addsample,
		projects,
	},
})
export default store