import React from 'react'
import { InitialStage } from '../../components'
const Project = () => {
  return (
    <div>
      <InitialStage />
    </div>
  )
}

export {Project}