import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Form, Row, Col, Typography, Upload, Image } from 'antd'
import { MyInput } from '../MyInput'
import { ApiCalls } from '../../shared/ApiCalls'
import { domainUrl } from '../../shared/constants'
import { checkAuthorization } from '../../shared/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared'
const { Dragger } = Upload;

const AddLabelModal = ({visiblemodal, onClose,getAllSamples, projectID}) => {

    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const { userToken } = checkAuthorization();
    
    const createLabel = async() => {
        setLoading(true)
        let data= form.getFieldsValue()
        let formData = new FormData()
        formData.append('name', data?.labelName)
        formData.append('project_id', projectID)
        try{
            const result = await ApiCalls.AddLabel.createLabel(formData)
            setLoading(false);
            getAllSamples();
            if(result){
                form.resetFields();
                onClose();
            }
            
        } catch(error){
            console.error(error)
            setLoading(false);
        }
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            createLabel(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

    return (
        <Modal width={600} 
            className='shadow-c modal'  
            title='Add Label'
            open={visiblemodal} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <Space style={{marginTop:'15px'}} className="w-100">
                    <Button onClick={() => form.submit()} loading={loading} className='sm-btn brand-bg text-white'>
                        Add
                    </Button>
                    <Button onClick={onClose} className='sm-btn'>
                        Cancel
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                requiredMark={false}
            >
                <Row gutter={12} justify={'center'}>
                    <Col span={24}> 
                        <MyInput
                            name="labelName"
                            label="Label name"
                            size='large'
                            required
                            message='please enter Label name'
                            value={form.getFieldValue("labelName") || ''}
                        />
                    </Col>
                </Row>
            </Form>            
        </Modal>
    ) 
}

export { AddLabelModal }