import { Row, Col} from "antd"
import { MyInput } from "../../../Forms"


const Student= ()=>{
    return (
        <Row gutter={[32,0]}>
            <Col span={12}>
                <MyInput
                    name="firstName"
                    label="First name"
                        placeholder= 'John'
                    required
                    message='please enter first name'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="lastName"
                    label="Last name"
                        placeholder= 'Doe'
                    required
                    message='please enter last name'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="email"
                    label="Email"
                        placeholder= 'myemail@gmail.com'
                    required
                    message='please enter email'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="className"
                    label="Class name"
                        placeholder= 'BS-CS'
                    required
                    message='please enter class name'
                    size='large'
                />
            </Col>
            <Col span={12}>
                <MyInput
                    name="password"
                    type='password'
                    label="Password"
                    required
                    message='please enter password'
                    placeholder='e.g A@123456'
                    size='large'
                />
            </Col>
        </Row>
    )
}
export {Student}