import React, { useEffect } from 'react'
import { Button, Flex, Typography, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, checkAuthorization, domainUrl } from '../../../shared'
import { SyncOutlined } from '@ant-design/icons'

const InitialStage = () => {

    const navigate = useNavigate()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { addproject, loadingAddProject } = useSelector(state => state?.projects)

    useEffect(() => {
        dispatch(actionsApi?.getAddProjects())
    }, [])

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddProjects())
    }

    

    return (
        <Flex justify='center' className='mb-3'>
            <div className='width-93'>
                <Flex gap={40} vertical justify='center' className='martop-100'>
                    <Flex gap='large' align='center' vertical className='w-100'>
                        <Typography.Title className='primary-heading m-0 mb-3 text-center' level={1}>
                            Your Projects.
                        </Typography.Title>
                        <Button onClick={()=>navigate('/projectname')} className='larg-btn brand-bg text-white'>
                            Create a new project
                        </Button>
                    </Flex>                    
                    <Flex gap={'small'} align='center' vertical style={{position:'relative'}}>
                        <Typography.Title level={2}>Project Name</Typography.Title>
                        <Flex gap={10} wrap>
                            {
                                loadingAddProject?(
                                    <div className='loadercs'>
                                        <SyncOutlined  spin style={{color:'var(--main-color)',fontSize: '24px'}}/>
                                    </div>
                                )
                            :
                                (
                                    addproject?.map((data,index)=>
                                        <Button key={index} className='larg-btn brand-bg text-white' 
                                            onClick={()=>navigate('/collectsample/'+data?.id)}
                                        >
                                            {
                                                data?.projectName
                                            }
                                        </Button>
                                    )
                                )
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        </Flex>
  )
}

export {InitialStage}