import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddProjects = createAsyncThunk('getProjectsStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingAddProjects())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/getAllProjects`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    const projectIds = result.projects.map(project => project.id);
                    dispatch(addProjectsResponse(result.projects))
                    dispatch(setPages(projectIds))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(addProjectsClear())
                message.error(error) 
            })
    }
)

const AddProjectSlice = createSlice({
    name: 'projects',
    initialState: {
        data: null,
        loading: false,
        addproject: null,
        loadingAddProject: false,
        pagination:{
            pageNo: 1,
            pageSize: 10,
            totalRecords: 0,
            projectIds: null,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        gettingAddProjects: state => {
            state.loadingAddProject = true
            state.addproject = null
        },
        addProjectsResponse: (state, action) => {
            state.addproject = action.payload
            state.loadingAddProject = false
        },
        addProjectsClear: state => {
            state.addproject = null
            state.loadingAddProject = false
        },
        changeaddProjectFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages: (state, action) => {
            state.pagination.projectIds = action.payload
        },
    },
})

export const { 
    gettingAddProjects, addProjectsResponse, addProjectsClear,
    changeaddProjectFilters, setPages
} = AddProjectSlice.actions;
export default AddProjectSlice.reducer;
