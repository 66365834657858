import React from 'react'
import { Col, Flex, Row, Typography } from 'antd'

const PrivacyPolicyComponent = () => {

    const data = [
        {
            title:'How is my account privacy?',
            desc:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus impedit repudiandae natus, iusto saepe doloribus veritatis sed sequi quia quibusdam voluptas nulla facere quas repellat labore, deserunt hic voluptatum velit?`
        },
        {
            title:'When registering an account?',
            desc:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas saepe ipsam ipsum. Minus tempora sunt veritatis, provident incidunt illum aliquid ullam fugiat neque, suscipit modi consequuntur repudiandae, qui distinctio. Est.`
        },
        {
            title:'Data notes',
            desc:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas saepe ipsam ipsum. Minus tempora sunt veritatis, provident incidunt illum aliquid ullam fugiat neque, suscipit modi consequuntur repudiandae, qui distinctio. Est. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas saepe ipsam ipsum. Minus tempora sunt veritatis, provident incidunt illum aliquid ullam fugiat neque, suscipit modi consequuntur repudiandae, qui distinctio. Est.`
        },
        {
            title:'How to ensure account security?',
            desc:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas saepe ipsam ipsum. Minus tempora sunt veritatis, provident incidunt illum aliquid ullam fugiat neque, suscipit modi consequuntur repudiandae, qui distinctio. Est. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas saepe ipsam ipsum. Minus tempora sunt veritatis, provident incidunt illum aliquid ullam fugiat neque, suscipit modi consequuntur repudiandae, qui distinctio. Est.`
        },
    ]

  return (
    <Flex justify='center'>
        <div className='width-93'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <Typography.Title level={1} className='primary-heading'>
                        Privacy Policy
                    </Typography.Title>
                </Col>
            </Row>
            <Col span={24}>
                {
                    data?.map((dat,d)=>
                        <Flex vertical gap={'large'} className='mb-3' key={d}>
                            <Typography.Title level={4} className='primary-heading m-0'>
                                {
                                    dat?.title
                                }
                            </Typography.Title>
                            <Typography.Text className='text-gray'>
                                {
                                    dat?.desc
                                }
                            </Typography.Text>
                        </Flex>
                    )
                }
            </Col>
        </div>
    </Flex>
  )
}

export {PrivacyPolicyComponent}