import React from 'react'
import { ContactComponent } from '../../components'

const Contactus = () => {
  return (
    <div className='mar-10'>
      <ContactComponent />
    </div>
  )
}

export {Contactus}