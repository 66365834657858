import { useEffect } from "react"
import { Login, LoginIntro } from "../pages"
import { isUnAuthorize } from "../shared"

const Protected = ({isLoggedIn, children }) => {

    useEffect(()=>{
        if(isUnAuthorize())
            localStorage.clear()
    },[])

      if (isUnAuthorize() && !isLoggedIn) {
        return <LoginIntro/>
    
      }
      return children
}
export default Protected;
