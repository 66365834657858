import React,{ useEffect, useState } from 'react'
import { Image, Button, Space, Typography, Input} from 'antd'
import {useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
// import { UserDropDown, Notifications } from '../'
import { useSelector, useDispatch} from 'react-redux'
import {IconMenu2} from '@tabler/icons-react'
import { actionsApi } from '../../shared'
import { SideMenu } from './SidebarMenu'
import { RegistrationModal, LoginModal } from '../Structure/Modals'
import { UserDropDown } from './UserDropDown'
import './index.css'
const {Text}= Typography

const Navbar = () => {
    const dispatch= useDispatch()
    let location = useLocation()
    // const {data}= useSelector(state => state?.login)
    // const {data: cart}= useSelector(state => state?.cart)
    // const {count}= useSelector(state=> state?.notifications)
    const data={}
    const [token, setToken]= useState(null)
    const [showmenu, setShowMenu] = useState(false)
    const [logshow, setLogShow ] = useState(false)
    const [currentTab, setCurrentTab]= useState(null)
    const [openLoginModal, setOpenLoginModal]= useState(false)
    const [openRegistrationDrawer, setOpenRegistrationDrawer]= useState(false)

    useEffect(()=>{
        setCurrentTab(location?.pathname?.split("/")[1])
    }, [location])
    useEffect(()=>{
        // dispatch(actionsApi?.getCartList())
    }, [])
    useEffect(()=>{
        setToken(localStorage.getItem('token'))
    },[data])
  return (
    <>
        <div className='justify-center' id='navbar'>
            <div className='widthnav borderbottom width-90'>
                <div className='mainNavbar'>
                    <div className='navBar'>
                        <div>
                            <NavLink to='/'>
                                <Image 
                                    src='/assets/images/logo.png' 
                                    preview={false} 
                                    width="95px"
                                />
                            </NavLink>
                        </div>
                        <div className={showmenu?'menubar active':'menubar hide'}>
                            <ul>
                                <li>
                                    <NavLink to='/about-us'>
                                        <Button 
                                            type={currentTab==='about-us' ? 'primary':'default'}
                                            className={currentTab==='about-us' ? '': 'tab-button'}
                                            >
                                            {currentTab==="about-us" ? "About us" : <Text strong>About us</Text>}
                                        </Button>
                                    </NavLink>    
                                </li>    
                                <li>
                                    <NavLink to='/projects'>
                                        <Button 
                                            type={currentTab==='projects'?'primary':'default'}
                                            className={currentTab!=='projects'?'tab-button':''}
                                            >
                                            {currentTab==="projects" ? 'Projects' : <Text strong>Projects</Text>}
                                        </Button>
                                    </NavLink>    
                                </li>    
                                <li>
                                    <NavLink to='/course-work'>
                                        <Button 
                                            type={currentTab==='course-work'?'primary':'default'}
                                            className={currentTab!=='course-work'?'tab-button':''}
                                            >
                                            {currentTab==="course-work" ? "Course Work" : <Text strong>Course Work</Text>}
                                        </Button>
                                    </NavLink>    
                                </li>                   
                            </ul>
                        </div>
                    </div>
                    <Space className='w-100 justify-end'>   
                        {/* {
                            token &&
                            <Space  style={{marginRight:'10px'}} size={10}>
                                <Badge count={cart?.cart?.length} overflowCount={9}>
                                    <Button size='middle' shape='circle'>
                                        <NavLink to='/cart'>
                                            <Image src='/assets/img/carticon.png' width={'20px'} className='up' preview={false} />
                                        </NavLink>
                                    </Button>
                                </Badge>
                                <Badge count={ count} overflowCount={10}>
                                    <Notifications/>
                                </Badge>
                            </Space>
                        } */}
                        {
                            !token &&
                            <Space>
                                <Button
                                    type={currentTab!=='signup' ? 'primary':'default'}
                                    onClick={()=> setOpenLoginModal(true)}
                                    >
                                    Log In
                                </Button>
                                <Button 
                                    type={currentTab==='signup' ? 'primary':'default'}
                                    onClick={()=> setOpenRegistrationDrawer(true)}
                                    >
                                    Register
                                    <span onClick={()=>setLogShow(!logshow)}></span>
                                </Button>
                            </Space>
                        }
                        {/* {
                            token &&
                            <UserDropDown/>
                        } */}
                        <div className='hamburger'>
                            <Button 
                                onClick={() => {setShowMenu(!showmenu) }} 
                                icon={<IconMenu2/>}
                                >
                            </Button>
                        </div>
                    </Space>
                </div>
            </div>
            <SideMenu
                visible={showmenu}
                onClose={()=> {setShowMenu(false)}}
            />
        </div>
        <LoginModal open={openLoginModal} onClose={()=> setOpenLoginModal(false)}/>
        <RegistrationModal open={openRegistrationDrawer} onClose={()=> setOpenRegistrationDrawer(false)}/>
    </>
  )
}

export {Navbar}