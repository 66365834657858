import {Form, Radio, Typography} from 'antd';
export const MyRadio = ({withoutForm, name,label,mode,disabled,required,message,value, onChange,options, ...props}) => {
  return (
    <>
    {
        withoutForm ?
        <Radio.Group 
            onChange={onChange} 
            value={value}
            disabled={disabled || false} 
            {...props}
        >
            {
                options?.map(opt=><Radio value={opt?.id} key={opt?.id}>{opt?.name}</Radio>)
            }
        </Radio.Group>
        :
        <Form.Item
            name={name}
            label={<Typography.Text strong>{label}</Typography.Text>}
            rules={[
                {
                required,
                message,
                },
            ]}
        >
            <Radio.Group 
                onChange={onChange} 
                value={value}
                disabled={disabled || false} 
                {...props}
            >
                {
                    options?.map(opt=><Radio value={opt?.id} key={opt?.id}>{opt?.name}</Radio>)
                }
            </Radio.Group>
        </Form.Item>  
    }
    </>
  )
}