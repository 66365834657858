import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getAddSample = createAsyncThunk('getSampleStatistics',
    async (id, { dispatch, getState }) => {
        const {userToken}= checkAuthorization()
        dispatch(gettingAddSample())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/labels/project/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("result",result);
                
                if (result?.labels?.length>0) {
                    dispatch(addSampleResponse(result))
                }
                else 
                    throw result
            })
            .catch(error => { 
                dispatch(addSampleClear())
                // message.error(error) 
            })
    }
)
const AddSampleSlice = createSlice({
    name: 'addsample',
    initialState: {
        data: null,
        loading: false,
        addsample: null,
        loadingAddSample: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        gettingAddSample: state => {
            state.loadingAddSample=true
            state.addsample=null
        },
        addSampleResponse: (state, action)=>{
            state.addsample= action.payload
            state.loadingAddSample= false
        },
        addSampleClear: (state)=>{
            state.addsample= null
            state.loadingAddSample= false
        },
        changeaddSampleFilters: (state, action) => {
            state.filters = action.payload
        },
    },
})
export const { 
    gettingAddSample, addSampleResponse, addSampleClear,
    changeaddSampleFilters
} = AddSampleSlice.actions;
export default AddSampleSlice.reducer;