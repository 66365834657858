import React, { useState } from "react";
import { Button, Card, Col, Flex, Form, Row, Typography } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { ApiCalls } from "../../../shared/ApiCalls";
import { MyInput } from "../../MyInput";

const ProjectNameForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [proid, setProId] = useState("");

  const createProject = async(formData) => {
    setLoading(true)
    try{
        const result = await ApiCalls.AddProjectName.createProjectName(formData)
        setLoading(false);
        form.resetFields();
        if(result)
            navigate('/collecttrain')
        else
            navigate('/projectname')
        
    } catch(error){
        console.error('Error add master portal data', error)
    }
}

  const onFinish = async () => {
    try {
      const formData = await form.validateFields();
      createProject(formData);
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };
  return (
    <Flex justify="center" align="center" className="py-3">
      <div className="pro-Form mar-10">
        <Card className="card-login">
          <Typography.Title
            level={1}
            className="primary-heading text-center m-0 mb-3"
          >
            Your Projects.
          </Typography.Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Row gutter={16}>
              <Col span={24}>
                <MyInput
                  name="projectName"
                  label="Project name"
                  size="large"
                  required
                  message="please enter Project name"
                  value={form.getFieldValue("projectName") || ""}
                />
              </Col>
              <Col span={24}>
                <Flex justify="end" gap="small">
                  <Button
                    loading={loading}
                    htmlType="submit"
                    className="sm-btn brand-bg text-white"
                  >
                    Create
                  </Button>
                  <Button onClick={() => navigate(-1)} className="sm-btn ">
                    Cancel
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Flex>
  );
};

export { ProjectNameForm };
