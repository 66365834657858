import React, { useState } from 'react'
import { Button, Col, Flex, Form, Image, Input, Row, Typography } from 'antd'
import { MyInput } from '../../components'
import { ApiCalls } from "../../shared/ApiCalls"

const Instructor = () => {
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)

    
    const CreateInstructor = async(formData) => {
        setLoading(true)
        try{
            const result = await ApiCalls.AddInstructor.CreateInstructor(formData)
            setLoading(false);
            form.resetFields();
        } catch(error){
            console.error('Error add master portal data', error)
        }
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            CreateInstructor(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <Flex className='mt-5 py-3'>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'} justify={'space-between'}>
                <Col xs={24} sm={24} md={12}>
                    <Typography.Title className='primary-heading m-0 mb-3 text-center' level={1}>
                        Instructor.
                    </Typography.Title>
                    <Flex justify='center'>
                        <Image src='/assets/instructor.png' preview={false} width={'70%'} />
                    </Flex>
                </Col>
                <Col xs={24} sm={24} md={10}>
                    <Typography.Text className='body-text text-justify mb-3'>
                        Please provide your details to set up your account:
                    </Typography.Text>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <MyInput
                                    name="firstName"
                                    label="First name"
                                    size='large'
                                    required
                                    message='please enter first name'
                                    value={form.getFieldValue("firstName") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="lastName"
                                    label="Last name"
                                    size='large'
                                    required
                                    message='please enter last name'
                                    value={form.getFieldValue("lastName") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="email"
                                    label="Email"
                                    size='large'
                                    required
                                    message='please enter email'
                                    value={form.getFieldValue("email") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="universityProfileWebsite"
                                    label="University Profile Website"
                                    size='large'
                                    required
                                    message='please enter university profile website'
                                    value={form.getFieldValue("universityProfileWebsite") || ''}
                                />
                            </Col>
                            <Col span={24} className='mb-3'>
                                <Typography.Title className='secondary-heading m-0 mb-3' level={3}>
                                    To create a class account.
                                </Typography.Title>
                                <Typography.Text className='body-text text-justify'>
                                    If you are setting up an account for your class, please provide the following:
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="numberOfStudent"
                                    label="Number of students in your class"
                                    size='large'
                                    required
                                    message='please enter no of student'
                                    value={form.getFieldValue("numberOfStudent") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="className"
                                    label="Class name"
                                    size='large'
                                    required
                                    message='please enter class name'
                                    value={form.getFieldValue("className") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <MyInput
                                    name="password"
                                    type='password'
                                    label="Password"
                                    size='large'
                                    required
                                    message='please enter password'
                                    placeholder='e.g A@123456'
                                    value={form.getFieldValue("password") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <Button
                                    loading={loading} 
                                    htmlType="submit" 
                                    className='sm-btn brand-bg text-white'
                                >
                                    Log In
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {Instructor}