import React from 'react'
import { Divider, Flex, Image, Typography, Space} from 'antd'
import './index.css'
import { NavLink } from 'react-router-dom'

const {Text, Title}= Typography

const Footer = () => {

    const dataLink = [
        {
            linktext:'Contact Us',
            path:'/contact'
        },
        {
            linktext:'Faqs',
            path:'/faqs'
        },
        {
            linktext:'Accessibility',
            path:'/'
        },
        {
            linktext:'Privacy & Cookies Policy',
            path:'/privacypolicy'
        },
        {
            linktext:'Terms of Use',
            path:'/termcondition'
        },
    ]


  return (
    <div className='footer center secondary-bg'>
            <div className='width-93 my-5'>
                <Flex justify='space-between'>
                    <Flex vertical gap={'small'}>
                        <Image 
                            src='/assets/logo-1.png' 
                            preview={false} 
                            width={'90px'}
                            alt='ml-scholar'
                        />
                        <Title level={4} className='m-0'>
                            ML Scholar
                        </Title>
                    </Flex>
                    <Space size={12}>
                        {
                            dataLink?.map((data,index)=>
                                <Text strong>
                                    <NavLink to={data?.path} key={'footer-'+index} className='copyRight'>
                                        {data?.linktext}
                                    </NavLink>
                                </Text>
                            )
                        }
                    </Space>
                </Flex>
                <Divider className='bg-tertiary' />
                <Flex justify='center' gap="small">
                    <Text>
                        Copyright © 2024
                    </Text>
                    <Text strong>
                        <NavLink to='https://replatechnologies.com/' className='copyRight'> REPLA Technologies Pvt Ltd</NavLink>
                    </Text>
                </Flex>
            </div>
    </div>
  )
}

export {Footer}