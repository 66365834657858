import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { 
  AboutUs, 
  Contactus, 
  CourseWork, 
  Faqs, 
  HomePage, 
  Instructor, 
  Login, 
  LoginIntro, 
  PrivacyPolicy, 
  Project, 
  Register, 
  Student, 
  TermofUse 
} from '../pages'
import {Navbar} from '../components';
import { CollectSample, CollectTrainComponent, Footer, ProjectNameForm, StartTraining, Training } from '../components';
import Protected from './Protected';
import { actionsApi } from '../shared';
import { useDispatch } from 'react-redux';
import ScrollTop from '../components/ScrollTop';

const MLRouter = () => {

  const [isLoggedIn, setIsLoggedIn]= useState(null)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(!localStorage.getItem('token'))
        {
            dispatch(actionsApi?.loginResponse(null))
            setIsLoggedIn(false)
        }
        else
        {
            const userDetail= JSON.parse(localStorage.getItem('user'))
            dispatch(actionsApi?.loginResponse(userDetail))
            setIsLoggedIn(true)
        }
    },[])

  return (
    <BrowserRouter>
      <ScrollTop />
      <Navbar />
      <Routes>        
        <Route path="/loginIntro" element={<LoginIntro />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termcondition" element={<TermofUse />} />
        <Route path="/register" 
          element={
              <Register />
          } 
        />
        <Route path="/instructor" 
          element={
              <Instructor />
          } 
        />
        <Route path="/student" 
          element={
              <Student />
          }
        />
        <Route path="/projects" 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Project />
            </Protected>
        } 
          
        />
        <Route path="/projectname" 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <ProjectNameForm />
            </Protected>
          }
        />
        <Route path='/collecttrain' 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <CollectTrainComponent />
            </Protected>
          } 
        />
        <Route path='/collectsample/:id' 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <CollectSample />
            </Protected>
          } 
        />
        <Route path='/train' 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <Training />
            </Protected>
          } 
        />
        <Route path='/starttrain' 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <StartTraining />
            </Protected>
          } 
        />
        <Route path='/course-work' 
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <CourseWork />
            </Protected>
          } 
        />
        <Route path="/" element={<AboutUs />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default MLRouter