import React from 'react'
import { CourseworkCard } from '../../components'

const CourseWork = () => {
  return (
    <div>
        <CourseworkCard />
    </div>
  )
}

export {CourseWork}