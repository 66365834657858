import React from 'react'
import { Card, Flex, Typography } from 'antd'

const TermCondComponent = () => {

    const data = {
        title:'Terms and Conditions',
        listdoc:[
            {
                title:'Transaction Agreement',
                list:[
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                ]
            },
            {
                title:'Liability Statement',
                list:[
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                    {
                        text:`Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi modi ipsam, omnis dicta ipsa vel esse sequi hic aliquid sit cumque aspernatur animi quos numquam. Quo eaque eius reiciendis velit!`
                    },
                ]
            },
        ]
    }

  return (
    <Flex justify='center'>
        <div className='width-93'>
            <Card className='bg-transparent py-3 border0'>
                <Flex vertical gap={'large'} className='mb-3'>
                    <Typography.Title level={1} className='primary-heading m-0'>
                        {
                            data?.title
                        }
                    </Typography.Title>
                </Flex>
                {
                    data?.listdoc?.map((listdata,d)=>
                        <Flex vertical gap={'small'} className='mb-3' key={d}>
                            <Typography.Title level={4} className='m-0'>
                                {
                                    listdata?.title
                                }
                            </Typography.Title>
                            <ul className='ol'>
                                {
                                    listdata?.list?.map((list,l)=>
                                        <li key={l}>
                                            <Typography.Text>
                                                {
                                                    list?.text
                                                }
                                            </Typography.Text>
                                        </li>
                                    )
                                }
                            </ul>
                        </Flex>
                    )
                }
            </Card>
        </div>
    </Flex>
  )
}

export {TermCondComponent}