import React from 'react'
import { TermCondComponent } from '../../components'

const TermofUse = () => {
  return (
    <div className='mar-10'>
      <TermCondComponent />
    </div>
  )
}

export {TermofUse}