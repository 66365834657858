import { useState } from 'react'
import { Modal, Row, Col, Typography, Space, Form, Button} from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { MyInput } from '../../../Forms'
import { ApiCalls } from '../../../../shared/ApiCalls'
import { RegistrationModal } from '../RegistrationModal'
const {Text}= Typography

const LoginModal = ({open, onClose}) => {
  
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const [openModal, setOpenModal]= useState(false)
      
  
    const login = async () => {
        setLoading(true)
        let result
        // if(forgotPassword)
        //     appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        // else
        result= await ApiCalls?.auth?.login(form.getFieldsValue(['email', 'password']))
        form.resetFields()
        setLoading(false)
        if(result)
            onClose()
    }

  return (
    <>
        <Modal
            title={"Login"}
            width={500}
            onCancel={onClose}
            open={open}
            footer={null}
            centered
        >
            <Space direction='vertical' size={15} className='width-100 mt-3'>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={login}
                >
                    <Row gutter={[32,0]}>
                        <Col span={24}>
                            <MyInput
                                autoFocus={true}
                                name="email"
                                label="Email"
                                placeholder= 'johndoe@gmail.com'
                                required
                                message='please enter email'
                                size='large'
                            />
                        </Col>
                        <Col span={24}>
                            <MyInput
                                name="password"
                                label="Password"
                                placeholder= 'Password@123'
                                required
                                message='please enter password'
                                size='large'
                            />
                        </Col>
                    </Row>
                    <Col span={24}>
                        <Space direction='vertical' className='width-100' size={15}>
                            <Button 
                                loading={loading}
                                htmlType="submit"
                                type='primary'
                                size='large'
                                block
                            >
                                Login
                            </Button>
                            <Space direction='vertical' size={3}>
                                <Text className='link'>Forget Password?</Text>
                                <Space className='link'>
                                    <Text className='link'>Don't have an account?</Text> 
                                    <Link onClick={()=>{onClose(); setOpenModal(true) }} className='link'>Register Now</Link>
                                </Space>
                            </Space>
                        </Space>
                    </Col>
                    <Col span={24}>
                        
                    </Col>
                </Form>
            </Space>
        </Modal>
        <RegistrationModal open={openModal} onClose={()=> setOpenModal(false)}/>
    </>
  )
};
export {LoginModal}