import React from 'react'
import { Col, Flex, Image, Row, Space, Typography} from 'antd'
const {Title, Text}= Typography

const WhyChoose = () => {

    const data = {
      title:"Why Choose MLScholar?",
      desc:[
        {
          text:"Interactive Learning: Hands-on projects to apply machine learning concepts."
        },
        {
          text:"Practical Applications: Utilize machine learning in practical scenarios like brand logo recognition to see how theories work outside the classroom."
        },
        {
          text:"User-Friendly: Designed with students in mind, making complex algorithms approachable."
        },
        {
          text:"Begin your machine learning adventure with MLScholar and bring your classroom knowledge to life!"
        },
      ]
    }

    return (
        <Flex justify='center' className='bg-tertiary'>
          <div className='width-93 my-5'>
            <Row gutter={[32, 32]} align='middle' className='m-0'>
                <Col xs={24} sm={24} md={12}>
                  <Flex align='center' justify="space-evenly">
                    <Image 
                      src='/assets/stand-2.png' 
                      preview={false}
                      alt='ml-scholar'
                       width='85%'
                    />
                    <Image 
                      src='/assets/wave.png' 
                      preview={false}
                      alt='ml-scholar'
                       width='85%'
                    />
                  </Flex>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Space direction='vertical' size={10}>
                        <Title className='primary-heading m-0 mb-3 text-white' level={1}>
                            {
                              data?.title
                            }
                        </Title>
                        {
                          data?.desc?.map((data, index)=>
                            <Space size={12} key={index}>
                                <img
                                  src='/assets/icons/circle.png'
                                  alt='ml-scholar'
                                  width={20}
                                  height={20}
                                  className='mt-3'
                                />
                                <Text className='body-text text-justify'>
                                  {
                                    data?.text
                                  }
                                </Text>
                            </Space>
                          )
                        }
                    </Space>
                </Col>
            </Row>
          </div>
        </Flex>
      )
}

export {WhyChoose}

