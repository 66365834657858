import { Button, Flex, Typography, Row, Col, Image, Form, Space} from 'antd'
import { useNavigate } from 'react-router-dom'
import './index.css'
import { MyRadio } from '../../components/Forms'
import { Instructor } from './Instructor'
const {Title, Text, Link}= Typography

const Register = () => {

    const navigate = useNavigate()
    const [form] = Form.useForm()

  return ( 
    <Flex justify='center'>
        <Row 
            className='register width-93 my-5' 
            align="middle"
            gutter={[32, 32]}
        >
            <Col 
                span={14}
                className='height-100'
                style={{
                    background: "url(/assets/images/group.png)", 
                    backgroundSize:'40%',
                    backgroundPosition:'right bottom', 
                    border: '1px solid red',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <Flex 
                    vertical 
                    justify='space-between' 
                    className='height-100' 
                    style={{border: '1px solid red'}}
                >
                    <div>
                        <Title>Create Account on ML Scholar to Keep Track of Your Progress</Title>
                    </div>
                    <Space direction='vertical'>
                        <Space>
                            <div>1</div>
                            <Text strong>Account Information</Text>
                        </Space>
                        <Space>
                            <div>2</div>
                            <Text strong>Personal Information</Text>
                        </Space>
                    </Space>
                </Flex>
            </Col>
            <Col span={10}>
                <Space direction='vertical' size={12}>
                    <Typography.Title level={3} className='secondary-heading m-0 brand-color-2'>
                        Register.
                    </Typography.Title>
                    <Typography.Title level={1} className='primary-heading m-0 brandcolor'>
                        Welcome to MLScholar!
                    </Typography.Title>
                    <Typography.Text className='body-text block'>
                        We are thrilled to have you join our community. To get started, please tell us who are:
                    </Typography.Text>
                    <Text strong>Register as:</Text>
                    <MyRadio
                        withoutForm
                        options={[
                            {id:'instructor', name: 'instructor'},
                            {id:'student', name: 'student'}
                        ]}
                    />
                    <div>
                        <Button type='primary'>Next</Button>
                    </div>
                    <Space>
                        <Text>Already have an account?</Text>
                        <Link strong>Login Now</Link>
                    </Space>
                </Space>
                <Instructor/>
                {/* <Flex className='mb-3' vertical gap="large">

                    <Button onClick={()=>navigate('/instructor')} className='sm-btn brand-bg text-white'>
                        Instructor
                    </Button>
                    <Button onClick={()=>navigate('/student')} className='sm-btn brand-bg text-white'>
                        Student
                    </Button>
                </Flex> */}
            </Col>
        </Row>
    </Flex>
  )
}

export {Register}