import { Form, Button, Typography, Row, Col, Space} from "antd"
import { MyInput } from "../../components"
const {Text, Title}= Typography

const Instructor= ()=>{
    const [form] = Form.useForm()

    return (
        <Space>
            <Text className='body-text text-justify mb-3'>
                Please provide your details to set up your account:
            </Text>
            <Form
                form={form}
                layout='vertical'
                // onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <MyInput
                            name="firstName"
                            label="First name"
                            size='large'
                            required
                            message='please enter first name'
                            value={form.getFieldValue("firstName") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="lastName"
                            label="Last name"
                            size='large'
                            required
                            message='please enter last name'
                            value={form.getFieldValue("lastName") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="email"
                            label="Email"
                            size='large'
                            required
                            message='please enter email'
                            value={form.getFieldValue("email") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="universityProfileWebsite"
                            label="University Profile Website"
                            size='large'
                            required
                            message='please enter university profile website'
                            value={form.getFieldValue("universityProfileWebsite") || ''}
                        />
                    </Col>
                    <Col span={24} className='mb-3'>
                        <Title className='secondary-heading m-0 mb-3' level={3}>
                            To create a class account.
                        </Title>
                        <Text className='body-text text-justify'>
                            If you are setting up an account for your class, please provide the following:
                        </Text>
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="numberOfStudent"
                            label="Number of students in your class"
                            size='large'
                            required
                            message='please enter no of student'
                            value={form.getFieldValue("numberOfStudent") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="className"
                            label="Class name"
                            size='large'
                            required
                            message='please enter class name'
                            value={form.getFieldValue("className") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <MyInput
                            name="password"
                            type='password'
                            label="Password"
                            size='large'
                            required
                            message='please enter password'
                            placeholder='e.g A@123456'
                            value={form.getFieldValue("password") || ''}
                        />
                    </Col>
                    <Col span={24}>
                        <Button
                            // loading={loading} 
                            htmlType="submit" 
                            className='sm-btn brand-bg text-white'
                        >
                            Log In
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Space>
    )
}
export {Instructor}