import { message } from "antd"
import { domainUrl } from "../../constants"

const login= (data)=>{

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl + "/login", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success) {
                localStorage.setItem('token', JSON.stringify(result?.user?.token))
                localStorage.setItem('user', JSON.stringify(result?.user))
                window.location.href='/'
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            return 0
        })
    )
}
const auth= {
    login
}
export {auth}