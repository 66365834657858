import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl, newDomainUrl } from "../../constants";
const createLabel = (formData) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };
    return(
        fetch(domainUrl + `/labels`, requestOptions)
        .then(response => {
            if (response?.status==201) {
                message.success(response?.statusText);
                return 1
            } else {
                message.error(response?.statusText);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};

const DeleteAndroidApi=(id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/labels/${id}`, requestOptions)
        .then(response => {
            if (response?.status==204) {
                message.success("deleted");
                return 1
            } else {
                message.error(response?.statusText);
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
  }
const AddLabel = {
    createLabel,
    DeleteAndroidApi
}
export { AddLabel }