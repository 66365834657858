import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const login = createAsyncThunk('login',
    async (data, { dispatch, getState }) => {
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + "/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    localStorage.setItem('token', JSON.stringify(result?.user?.token))
                    dispatch(loginResponse(result?.user))
                    window.location.href='/'
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(loginClear()) 
            })
    }
)
export const forgotPassword = createAsyncThunk('forgot',
    async (data, { dispatch, getState }) => {
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + "/forgotPassword", requestOptions)
            .then(response => response.json())
            .then(result => {
                dispatch(loginClear())
                if (result.success) 
                    message.success(result?.message)
                else 
                    throw result?.error
            })
            .catch(error => { 
                dispatch(loginClear())
                message.error(error)
            })
    }
)

export const AuthCheck = createAsyncThunk('LoginCheck',
    async (_, { dispatch, getState }) => {
        const { userToken }= checkAuthorization()
        dispatch(gettingLoginCheck())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/loginCheck`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    const user = localStorage.getItem('userRole');
                    dispatch(loginResponse(JSON.parse(user)))
                }
                else 
                throw result?.message
            })
            .catch(error => {
                dispatch(loginClear())
                // message.error(error)
            })
    }
)


const loginSlice = createSlice({
    name: 'login',
    initialState: {
        data: null,
        loading: false,
        LoginCheckloading: false
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        loginResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
            state.LoginCheckloading = false
        },
        loginClear: state => {
            state.data = null
            state.loading = false
            state.LoginCheckloading = false
        },
        gettingLoginCheck: state => {
            state.LoginCheckloading = true
        }
    }
})

export const { getting, loginResponse, loginClear, gettingLoginCheck } = loginSlice.actions;
export default loginSlice.reducer;