
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck } from "./action/Login"
//dashboard
import { getAddSample } from "./action/addsample"
import { getAddProjects } from "./action/projects"


export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //addandroidbox 
    // getBoxStatistics,
    getAddSample, 

    // projects
    getAddProjects
}
