import React from 'react'
import { Col, Flex, Row, Typography, Collapse } from 'antd'

const FaqsComponent = () => {

    const items = [
        {
          key: '1',
          label: 'Question no 1',
          children: <Typography.Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
          </Typography.Text>,
        },
        {
          key: '2',
          label: 'Question no 2',
          children: <Typography.Text>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
        </Typography.Text>,
        },
        {
          key: '3',
          label: 'Question no 3',
          children: <Typography.Text>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
        </Typography.Text>,
        },
        {
            key: '3',
            label: 'Question no 4',
            children: <Typography.Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
          </Typography.Text>,
          },
          {
            key: '3',
            label: 'Question no 5',
            children: <Typography.Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
          </Typography.Text>,
          },
          {
            key: '3',
            label: 'Question no 6',
            children: <Typography.Text>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Id suscipit mollitia cupiditate, voluptatem ipsam sapiente, quae eos quod, recusandae obcaecati aut quos labore distinctio? Quae esse delectus perferendis. Eius, perspiciatis.
          </Typography.Text>,
          },
      ];

    const onChange = (key) => {
        console.log(key);
    };

  return (
    <Flex justify='center'  className='mar-10 mb-3'>
        <div className='width-93'>
            <Row gutter={[16,16]} justify='center'>
                <Col span={24}>
                  <Typography.Title level={1} className='primary-heading'>
                    FAQs
                  </Typography.Title>
                </Col>
                <Col span={24}>
                    <Collapse 
                      items={items}
                      size='large'
                      defaultActiveKey={['1']}
                      onChange={onChange} 
                    />
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {FaqsComponent}