import React from 'react'
import { Button, Flex, Space, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const Training = () => {

    const navigate = useNavigate()

    const data = {
        primarytext:"Progress Update.",
        desc: "You've successfully gathered image samples for the computer to recognize different labels.",
        list:[
            {
                listtext:"-Label 1: # examples collected"
            },
            {
                listtext:"-Label 2: # examples collected"
            },
        ],
        secondarytext:"What's Next?",
        descsecondary:"Are you ready to begin training your machine learning model? Use the examples you've collected to teach the computer to recognize these labels.",
        lastdesc:"Or, if you need to gather more examples, you can return to the training page."
        
    }

  return (
    <Flex className='mar-10 pt-2' justify='center'>
        <div className='width-93'>
            <Space direction='vertical' size={20}>
                <Typography.Title level={2} className='m-0 secondary-heading '>
                    {
                        data?.primarytext
                    }
                </Typography.Title>
                <Typography.Text className='body-text '>
                    {
                        data?.desc
                    }
                </Typography.Text>
                <ul>
                    {
                        data?.list?.map((dat,d)=>
                            <li key={d}>
                                <Typography.Text className='body-text'>
                                    {
                                        dat?.listtext
                                    }
                                </Typography.Text>
                            </li>
                        )
                    }
                </ul>
                <Typography.Title level={2} className='secondary-heading
                 m-0'>
                    {
                        data?.secondarytext
                    }
                </Typography.Title>
                <Typography.Text className='body-text'>
                    {
                        data?.descsecondary
                    }
                </Typography.Text>
                <Button onClick={()=>navigate('/starttrain')} className='med-btn brand-bg text-white'>
                    Start Training Now
                </Button>
                <Typography.Text className='body-text'>
                    {
                        data?.lastdesc
                    }
                </Typography.Text>
                <Button onClick={()=>navigate('/')} className='med-btn brand-bg text-white'>
                    Collect More Examples
                </Button>
            </Space>
        </div>
    </Flex>
  )
}

export {Training}