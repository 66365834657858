import React from 'react'
import { PrivacyPolicyComponent } from '../../components'

const PrivacyPolicy = () => {
  return (
    <div className='mar-10'>
      <PrivacyPolicyComponent />
    </div>
  )
}

export {PrivacyPolicy}