import { message } from "antd";
import { domainUrl } from "../../constants";
import { checkAuthorization } from "../../helpers";


const createInstructor = (payload) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json")
    myHeaders.append('Authorization', userToken)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
    }

    return (
        fetch(domainUrl + '/instructorRegister', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}

const createStudent = (payload) => {
    
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow'
    };

    return(
        fetch(domainUrl + '/studentRegister', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
};

const registration = {
    createInstructor,
    createStudent
}

export {registration}