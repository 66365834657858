import React from "react";
import { Row, Col, Form, Typography, Button, Flex, Card } from "antd";
import "./index.css";
import { MyInput } from "../MyInput";

const { Text } = Typography;
const ContactComponent = () => {
    const [form] = Form.useForm();

    const contacts = [
        { img: "map.png", description: "8799 NewYork Ave.Newyork, Abc 39495" },
        { img: "phone.png", description: "(567) 000 - 12345" },
        { img: "mail.png", description: "mlscholar@gmail.com" },
    ];

    return (
        <Flex justify="center">
            <div className="width-93 py-3">
                <Row gutter={[64, 24]}>
                    <Col span={24}>
                        <Flex vertical gap={10} className="">
                            <Typography.Title level={1} className='primary-heading m-0'>
                                Get In Touch
                            </Typography.Title>
                            <Typography.Title level={4} className="text-gray m-0">
                                We’re Here to Help You
                            </Typography.Title>
                        </Flex>
                    </Col>
                    <Col lg={{ span: 10 }} sm={{ span: 24 }} xs={{ span: 24 }} >
                        <Card className="secondary-bg py-3">
                            {contacts?.map((contact, c) => (
                                <Flex key={c} className="sm-card" align="center" gap={10}>
                                <img src={"/assets/icons/" + contact?.img} alt="repla" width="40px" height="auto" />
                                    <div>
                                        <Typography.Title level={5} className="m-0">
                                            {contact?.description}
                                        </Typography.Title>
                                    </div>
                                </Flex>
                            ))}
                        </Card>
                    </Col>
                    <Col lg={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }} className="py-3">
                        <Form layout="vertical" onFinish={() => {}} form={form}>
                            <Row gutter={[16, 0]}>
                                <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <MyInput
                                        name="name"
                                        label="Name"
                                        size='large'
                                        required
                                        message='please enter name'
                                        value={form.getFieldValue("name") || ''}
                                    />
                                </Col>
                                <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <MyInput
                                        name="email"
                                        label="Email"
                                        size='large'
                                        required
                                        message='please enter email'
                                        value={form.getFieldValue("email") || ''}
                                    />
                                </Col>
                                <Col md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                    <MyInput
                                        name="subject"
                                        label="Subject"
                                        size='large'
                                        required
                                        message='please enter subject'
                                        value={form.getFieldValue("subject") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <MyInput
                                        textArea
                                        name="note"
                                        label="Note"
                                        size='large'
                                        required
                                        message='please enter note'
                                        value={form.getFieldValue("note") || ''}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Flex className="py-3">
                                        <Button htmlType="submit" className='sm-btn brand-bg text-white'>Submit</Button>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Flex>
    );
};
export { ContactComponent };
