const isUnAuthorize= ()=>{
    const userToken = localStorage.getItem('token')
    if(!userToken)
        return 1
    return 0
}
const checkAuthorization= ()=>{
    const tokenString = localStorage.getItem('token');
    let userToken = JSON.parse(tokenString)
    userToken= `Bearer ${userToken}`
    const userId= localStorage?.getItem("userId") 
    if(!userToken)
    {
        localStorage.clear()
        window.location.href='/loginIntro'
    }
    return ({userToken, userId})
}
export {isUnAuthorize, checkAuthorization}