import { Drawer, Typography, Space, Form, Button} from 'antd'
import { useNavigate } from 'react-router-dom'
import { MyRadio } from '../../../Forms'
import { Instructor } from './Instructor'
import { useRef, useState } from 'react'
import { Student } from './Student'
import { ApiCalls } from '../../../../shared/ApiCalls'
const {Text, Title, Link}= Typography

const RegistrationModal = ({open, onClose}) => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [registrationType, setRegistrationType]= useState('instructor')
    const submitButton= useRef()
    const [loading, setLoading]= useState(false)
    const createInstructorStudent = async () => {
        setLoading(true)
        const data = form.getFieldsValue()
        let result
        if(registrationType==="instructor")
            result = await ApiCalls?.registration?.createInstructor(data)
        else
            result = await ApiCalls?.registration?.createStudent(data)
        setLoading(false)
        form.resetFields()
        if(result)
            onClose()
    }
  return (
    <Drawer
        title="Register."
        width={700}
        onClose={onClose}
        open={open}
        footer={
            <Space>
                <Button onClick={onClose}>Cancel</Button>,
                <Button type='primary' onClick={()=> submitButton.current.click()}>Register</Button>
            </Space>
        }
    >
        <Space direction='vertical' size={15}>
            <Title level={2} className='primary-heading m-0 brandcolor'>
                Welcome to MLScholar!
            </Title>
            <Title className='brand-color-2 m-0' level={4}>
                We are thrilled to have you join our community. To get started, please tell us who are:
            </Title>
            <Space>
                <p className='my-0'>Already have an account?</p>
                <Link className='brand-color'>Login Now</Link>
            </Space>
            <Space direction='vertical'>
                <Text strong>Register as:</Text>
                <MyRadio
                    withoutForm
                    value={registrationType}
                    onChange={(e=> setRegistrationType(e.target.value))}
                    options={[
                        {id:'instructor', name: 'instructor'},
                        {id:'student', name: 'student'}
                    ]}
                />
            </Space>
            <Title className='brand-color m-0 mb-3' level={4}>
                Please provide your details to set up your account:
            </Title>
        </Space>
        <Form
            form={form}
            layout='vertical'
            onFinish={createInstructorStudent}
        >
            {
                registrationType === "instructor" ? 
                <Instructor/> : <Student/>
            }
            <Button loading={loading} className="display-none" ref={submitButton} htmlType="submit">submit</Button>
        </Form>
    </Drawer>
  )
};
export {RegistrationModal}